import { AddToast } from "react-toast-notifications";
import { commify, formatUnits } from "@ethersproject/units";
import { BigNumberish, BigNumber } from "@ethersproject/bignumber";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DEV_WALLETS } from "./config";

import {
    ContractStrategy,
    ContractWallet,
    Strategy,
    VestingType,
    Wallet,
} from "@src/ts/interfaces";

import { CHAIN_ID } from "@src/config";
import { NETWORKS } from "@src/constants";
import { is_browser } from "@src/constants";

dayjs.extend(utc);
dayjs.extend(timezone);

const network = NETWORKS[CHAIN_ID];

const day_in_seconds = 24 * 60 * 60;

export const getRandomInt = (max: number): number =>
    Math.floor(Math.random() * max);

export const getRPCUrl = (): string =>
    network?.rpc[getRandomInt(network?.rpc.length)];

export const executeTx = async (
    // eslint-disable-next-line
    promise: Promise<any>,
    message: string,
    addToast: AddToast,
    callback?: () => Promise<void>,
): Promise<string> => {
    try {
        addToast("Awaiting successful transaction", {
            appearance: "info",
        });
        const tx = await promise;
        await tx.wait();
        addToast(`${message || "Success"}`, {
            appearance: "success",
        });
        if (callback) await callback();

        return tx.hash;
    } catch (err) {
        addToast(err.error?.data?.message || err.message, {
            appearance: "error",
        });
    }
    return "";
};

const dev_wallets = DEV_WALLETS.map((w): string => w.toLowerCase());

export const isOwner = (account: string, owner: string): boolean => {
    return (
        account &&
        (account.toLowerCase() === owner.toLowerCase() ||
            dev_wallets.includes(account.toLowerCase()))
    );
};

export const cancellablePromise = (
    // eslint-disable-next-line
    promise: Promise<any | void>,
): { promise: Promise<unknown>; cancel: () => void } => {
    const isCancelled = { value: false };
    const wrappedPromise = new Promise((res, rej) => {
        promise
            .then((d) => {
                return isCancelled.value ? rej(isCancelled) : res(d);
            })
            .catch((e) => {
                rej(isCancelled.value ? isCancelled : e);
            });
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            isCancelled.value = true;
        },
    };
};

/**
 *
 * @param {("Account"|"Transaction")} type
 * @param {[number, string]} data
 */
export function formatChainScanLink(
    type: "Account" | "Transaction",
    hash: string,
): string {
    switch (type) {
        case "Account": {
            return `https://${network.explorer_url}/address/${hash}`;
        }
        case "Transaction": {
            return `https://${network.explorer_url}/tx/${hash}`;
        }
    }
}

/**
 * @name parseBalance
 *
 * @param {import("@ethersproject/bignumber").BigNumberish} balance
 * @param {number} decimals
 * @param {number} decimalsToDisplay
 *
 * @returns {string}
 */
export const parseBalance = (
    balance: BigNumberish,
    decimals = 18,
    decimalsToDisplay = 2,
): string =>
    commify(Number(formatUnits(balance, decimals)).toFixed(decimalsToDisplay));

export function shortenHex(hex: string, length = 4): string {
    return `${hex.substring(0, length + 2)}…${hex.substring(
        hex.length - length,
    )}`;
}

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (): Promise<boolean> => {
    const provider = (window as WindowChain).ethereum;

    if (provider) {
        try {
            await provider.request({
                method: "wallet_addEthereumChain",
                params: [
                    {
                        chainId: `0x${CHAIN_ID.toString(16)}`,
                        chainName: network.network_name,
                        nativeCurrency: {
                            name: network.symbol,
                            symbol: network.symbol,
                            decimals: 18,
                        },
                        rpcUrls: [getRPCUrl()],
                        blockExplorerUrls: [`https://${network.explorer_url}/`],
                    },
                ],
            });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    } else {
        console.error(
            "Can't setup the BSC network on metamask because window.ethereum is undefined",
        );
        return false;
    }
};

export const getDataFromStrategy = (
    strategy: Strategy,
    amount: BigNumber,
    decimals = 18,
): [t: number, v: number][] => {
    switch (strategy.type) {
        case VestingType.Linear:
            return getLinearChartData(strategy, amount, decimals);

        case VestingType.Monthly:
            return getMonthlyChartData(strategy, amount, decimals);

        case VestingType.Interval:
            return getIntervalChartData(strategy, amount, decimals);

        default:
            return [];
    }
};

export const getIntervalChartData = (
    strategy: Strategy,
    amount: BigNumber,
    decimals = 18,
): [t: number, v: number][] => {
    const cliff = dayjs(strategy.cliff);
    const { interval, unlock_per_interval } = strategy;
    const num_intervals = Math.ceil(100 / Number(unlock_per_interval));
    let curr_ts = cliff.unix();
    const timestamps = [...Array(num_intervals).keys()].map(() => {
        curr_ts = curr_ts + interval * day_in_seconds;
        return curr_ts;
    });

    return getDataFromTimestamps(strategy, amount, decimals, timestamps);
};

export const getMonthlyChartData = (
    strategy: Strategy,
    amount: BigNumber,
    decimals = 18,
): [t: number, v: number][] => {
    return getDataFromTimestamps(
        strategy,
        amount,
        decimals,
        strategy.timestamps,
    );
};

export const getDataFromTimestamps = (
    strategy: Strategy,
    amount: BigNumber,
    decimals: number,
    timestamps: number[],
): [t: number, v: number][] => {
    const start_date = dayjs(strategy.start_date);
    const cliff = dayjs(strategy.cliff);
    const initial_unlock = Number(strategy.initial_unlock) * 10;
    const { interval, unlock_per_interval } = strategy;

    const out = [];

    const waiting_period = cliff.diff(start_date, "days");

    // add this value to initial unlock
    const initial = amount.mul(initial_unlock).div(1000);
    const disp_initial = Number(formatUnits(initial, decimals));
    // if cliff is different to start date, add data points to show no tokens will be released
    if (start_date.format("DD/MM/YY") !== cliff.format("DD/MM/YY")) {
        for (let i = 0; i < waiting_period; i += interval) {
            const d = {
                date: start_date.clone().add(i, "days").toDate().getTime(),
                amount: disp_initial,
            };
            out.push(d);
        }
    }
    // calculate remaining by subtracting amount earned during wait
    const remaining = amount.sub(initial);

    // 1. in between each timestamp - add one day with same value, until reached timestamp and increase value by Math.min(unlock, remaining)
    let current_dist = initial;
    let current_time = cliff.unix();

    timestamps.forEach((t) => {
        while (current_time < t) {
            const d = {
                date: current_time * 1000,
                amount: Number(formatUnits(current_dist, decimals)),
            };
            out.push(d);
            current_time += day_in_seconds;
        }

        const left = amount.sub(current_dist);
        const per_interval = remaining
            .mul(Number(unlock_per_interval) * 10)
            .div(1000);

        current_dist = current_dist.add(
            per_interval.gt(left) ? left : per_interval,
        );
    });

    // push final data point with total tokens
    out.push({
        date: timestamps[timestamps.length - 1] * 1000,
        amount: Number(formatUnits(remaining.add(initial), decimals)),
    });

    return out.map(({ date, amount }) => [date, amount]);
};

export const getLinearChartData = (
    strategy: Strategy,
    amount: BigNumber,
    decimals = 18,
): [t: number, v: number][] => {
    const start_date = dayjs(strategy.start_date);
    const cliff = dayjs(strategy.cliff);
    const end_date = dayjs(strategy.end_date);
    const initial_unlock = Number(strategy.initial_unlock) * 10;

    const vesting_period = end_date.diff(cliff, "days");
    const waiting_period = cliff.diff(start_date, "days");

    const interval = 1; // days
    const out = [];

    // add this value to initial unlock
    const initial = amount.mul(initial_unlock).div(1000);
    const disp_initial = Number(formatUnits(initial, decimals));
    // if cliff is different to start date, add data points to show no tokens will be released
    if (start_date.format("DD/MM/YY") !== cliff.format("DD/MM/YY")) {
        for (let i = 0; i < waiting_period; i += interval) {
            const d = {
                date: start_date.clone().add(i, "days").toDate().getTime(),
                amount: disp_initial,
            };

            out.push(d);
        }
    }
    // calculate remaining by subtracting amount earned during wait
    const remaining = amount.sub(initial);

    // generate data points for every <interval> days during vesting period
    for (let i = 0; i < vesting_period; i += interval) {
        out.push({
            date: cliff.clone().add(i, "days").toDate().getTime(),
            amount: Number(
                formatUnits(
                    initial.add(
                        remaining
                            .div(BigNumber.from(vesting_period))
                            .mul(BigNumber.from(i)),
                    ),
                    decimals,
                ),
            ),
        });
    }
    // push final data point with total tokens
    out.push({
        date: end_date.toDate().getTime(),
        amount: Number(formatUnits(remaining.add(initial), decimals)),
    });

    return out.map(({ date, amount }) => [date, amount]);
};

export function createClass(name: string, rules: string): void {
    const style = (document as StyleDocument).createElement("style");
    style.type = "text/css";
    document
        .getElementsByTagName("head")[0]
        .appendChild(style as unknown as Node);
    if (!(style.sheet || {}).insertRule)
        (style.styleSheet || style.sheet).addRule(name, rules);
    else style.sheet.insertRule(name + "{" + rules + "}", 0);
}

const calculateReleasable = (
    wallet: ContractWallet,
    strategy: ContractStrategy,
) => {
    return BigNumber.from(calculateVestAmount(wallet, strategy)).sub(
        wallet.distributedAmount,
    );
};

const calculateVestAmount = (
    wallet: ContractWallet,
    strategy: ContractStrategy,
) => {
    // initial unlock
    const initial = BigNumber.from(wallet.dcbAmount)
        .mul(strategy.initialUnlockPercent)
        .div(1000);

    const start = Number(strategy.start);
    const cliff = Number(strategy.cliff);
    const now = dayjs().unix();

    if (wallet.revoke) {
        return wallet.distributedAmount;
    }

    if (now < start) {
        return 0;
    } else if (now >= start && now < cliff) {
        return initial;
    } else if (now >= cliff) {
        switch (strategy.vestType.toString() as VestingType) {
            case VestingType.Linear:
                return calculateVestAmountForLinear(wallet, strategy);
            case VestingType.Monthly:
                return calculateVestAmountForMonthly(wallet, strategy);
            case VestingType.Interval:
                return calculateVestAmountForInterval(wallet, strategy);
            default:
                return BigNumber.from(0);
        }
    }
};

const calculateVestAmountForInterval = (
    w: ContractWallet,
    s: ContractStrategy,
) => {
    const initial = w.dcbAmount.mul(s.initialUnlockPercent).div(1000);
    const remaining = w.dcbAmount.sub(initial);

    const intervalsPassed = BigNumber.from(dayjs().unix())
        .sub(s.cliff)
        .div(s.interval);
    const totalUnlocked = intervalsPassed.mul(s.unlockPerInterval);

    if (totalUnlocked.gte(1000)) {
        return w.dcbAmount;
    } else {
        return initial.add(remaining.mul(totalUnlocked).div(1000));
    }
};

const calculateVestAmountForLinear = (
    w: ContractWallet,
    s: ContractStrategy,
) => {
    const initial = BigNumber.from(w.dcbAmount)
        .mul(s.initialUnlockPercent)
        .div(1000);
    // remaining locked token
    const remaining = w.dcbAmount.sub(initial); //More accurate
    // return initial unlock + remaining x % of time passed
    return initial.add(
        remaining
            .mul(BigNumber.from(dayjs().unix()).sub(s.cliff))
            .div(s.duration),
    );
};

const calculateVestAmountForMonthly = (
    w: ContractWallet,
    s: ContractStrategy,
) => {
    const block_timestamp = BigNumber.from(dayjs().unix());
    const initial = w.dcbAmount.mul(s.initialUnlockPercent).div(1000);
    const remaining = w.dcbAmount.sub(initial);

    if (block_timestamp.gt(s.timestamps[s.timestamps.length - 1])) {
        return w.dcbAmount;
    } else {
        const multi = findCurrentTimestamp(
            s.timestamps.map((t) => t.toNumber()),
            block_timestamp.toNumber(),
        );

        const totalUnlocked = BigNumber.from(multi).mul(s.unlockPerInterval);

        return initial.add(remaining.mul(totalUnlocked).div(1000));
    }
};

const findCurrentTimestamp = (timestamps: number[], target: number) => {
    let last = timestamps.length;
    let first = 0;
    let mid = 0;

    if (target < timestamps[first]) {
        return 0;
    }

    if (target >= timestamps[last - 1]) {
        return last - 1;
    }

    while (first < last) {
        mid = Math.floor((first + last) / 2);

        if (timestamps[mid] == target) {
            return mid + 1;
        }

        if (target < timestamps[mid]) {
            if (mid > 0 && target > timestamps[mid - 1]) {
                return mid;
            }

            last = mid;
        } else {
            if (mid < last - 1 && target < timestamps[mid + 1]) {
                return mid + 1;
            }

            first = mid + 1;
        }
    }
    return mid + 1;
};

export const mapWallets = (
    wallet: ContractWallet,
    strategy: ContractStrategy,
): Wallet => ({
    disabled: wallet.disabled,
    revoked: wallet.revoke,
    amount: wallet.dcbAmount.toString(),
    released: wallet.distributedAmount.toString(),
    releasable: calculateReleasable(wallet, strategy).toString(),
    wallet: wallet.wallet,
});

export const getAddParams = (state: {
    [key: string]: string;
}): (string | number)[] => {
    const {
        name,
        start_date,
        end_date,
        initial_unlock,
        revocable,
        cliff,
        interval,
        unlock_per_interval,
        type,
    } = state;

    const start = dayjs(start_date + ":00.000Z").unix();
    const _cliff = dayjs(cliff + ":00.000Z").unix() - start;
    const per_interval = Math.floor(Number(unlock_per_interval || 0) * 10);
    const unlock = Math.round(Number(initial_unlock) * 10);

    switch (type as VestingType) {
        case VestingType.Linear:
            return [
                name,
                _cliff,
                start,
                dayjs(end_date + ":00.000Z").unix() - (start + _cliff), // duration
                unlock,
                revocable,
                0,
                0,
                0,
                VestingType.Linear,
            ];
        case VestingType.Monthly:
            return [
                name,
                _cliff,
                start,
                0,
                unlock,
                revocable,
                0,
                per_interval,
                interval,
                VestingType.Monthly,
            ];
        case VestingType.Interval:
            return [
                name,
                _cliff,
                start,
                0,
                unlock,
                revocable,
                Number(interval || "0") * 60 * 60 * 24, // interval length
                per_interval,
                0,
                VestingType.Interval,
            ];
        default:
            return [];
    }
};

export const getSetParams = (
    id: number,
    state: {
        [key: string]: string;
    },
): (string | number)[] => {
    const {
        name,
        start_date,
        end_date,
        initial_unlock,
        revocable,
        cliff,
        interval,
        unlock_per_interval,
    } = state;

    const start = dayjs(start_date + ":00.000Z").unix();
    const _cliff = dayjs(cliff + ":00.000Z").unix() - start;
    const per_interval = Math.floor(Number(unlock_per_interval || 0) * 10);
    const unlock = Math.round(Number(initial_unlock) * 10);

    switch (state.type as VestingType) {
        case VestingType.Linear:
            return [
                id,
                name,
                _cliff,
                start,
                dayjs(end_date + ":00.000Z").unix() - (start + _cliff), // duration
                unlock,
                revocable,
                0,
                0,
            ];
        case VestingType.Interval:
            return [
                id,
                name,
                _cliff,
                start,
                0,
                unlock,
                revocable,
                Number(interval || "0") * 60 * 60 * 24, // interval length
                per_interval,
            ];
        // cannot update monthly so not handling
        default:
            return [];
    }
};

export const getIntervalEndDate = (strategy: Strategy): string => {
    const { interval, unlock_per_interval, cliff } = strategy;
    const num_intervals = Math.ceil(100 / Number(unlock_per_interval));

    return dayjs(cliff)
        .add(num_intervals * interval, "days")
        .toISOString();
};

export const getEndDate = (strategy: Strategy): string => {
    switch (strategy.type) {
        case VestingType.Monthly:
            return dayjs(
                strategy.timestamps[strategy.timestamps.length - 1] * 1000,
            ).toISOString();
        case VestingType.Interval:
            return getIntervalEndDate(strategy);
        default:
            return strategy.end_date;
    }
};

export const mapContractStrategy = (
    s: ContractStrategy,
    as_utc = false,
): Strategy => {
    const {
        id,
        name,
        cliff,
        start,
        duration,
        initialUnlockPercent,
        revocable,
        vestType,
        interval,
        unlockPerInterval,
        timestamps,
    } = s;

    const is_monthly = vestType === Number(VestingType.Monthly);

    const current_tz = dayjs.tz.guess();

    return {
        id,
        name,
        cliff: dayjs(cliff.mul(1000).toNumber())
            .tz(as_utc ? "UCT" : current_tz, true)
            .format("YYYY-MM-DDTHH:mm"),
        initial_unlock: initialUnlockPercent.toNumber() / 10,
        start_date: dayjs(start.mul(1000).toNumber())
            .tz(as_utc ? "UCT" : current_tz, true)
            .format("YYYY-MM-DDTHH:mm"),
        end_date: dayjs(cliff.add(duration).mul(1000).toNumber())
            .tz(as_utc ? "UCT" : current_tz, true)
            .format("YYYY-MM-DDTHH:mm"),
        revocable,
        type: vestType.toString() as VestingType,
        interval: is_monthly
            ? timestamps.length > 1
                ? dayjs(timestamps[1].mul(1000).toNumber()).diff(
                      dayjs(timestamps[0].mul(1000).toNumber()),
                      "months",
                  )
                : 1
            : interval.div(24 * 60 * 60).toNumber(),
        unlock_per_interval: (unlockPerInterval.toNumber() / 10).toString(),
        timestamps: timestamps.map((n: BigNumber) => n.toNumber()),
    };
};

export const shouldDisplayCard = (): boolean => {
    if (is_browser) {
        if (JSON.parse(localStorage.getItem("has_seen_card")) === true) {
            return false;
        }
        return true;
    }
    return false;
};
